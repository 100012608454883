<template>
	<div>
		<b-row>
			<!-- <b-col cols="12">
				<h1 class="mb-1 badge badge-light-dark">GTS MESSAGES</h1>
			</b-col> -->
		</b-row>
		<b-card class="border">
			<b-row>
				<b-col cols="11">
					<b-row>
						<!-- <b-col sm="2">
							<b-form-group label="Type Message" label-for="v-Type">
								<v-select id="v-Type" v-model="t_type_message" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_type_message_opt" class="select-size-sm" :disabled="t_type_message_opt.length == 1" placeholder="Pilih Type Message" />
							</b-form-group>
						</b-col> -->
						<b-col sm="6">
							<b-form-group label="Stasiun" label-for="v-Name">
								<v-select id="v-Name" v-model="t_station_selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_station_opt" class="select-size-sm"  placeholder="Pilih Stasiun" />
								<!-- <b-form-input id="input-wmo" v-model="input.station_name" size="sm" class="std" disabled/> -->
							</b-form-group>
						</b-col>
						<!-- <b-col sm="2">
							<b-form-group label-size="sm" label="Tanggal" label-for="input-datepicker">
								<b-form-datepicker id="input-datepicker" :date-format-options="{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									}" locale="en" size="sm" placeholder="Tanggal"
									v-model="t_tanggal" :max="max" />
							</b-form-group>
						</b-col>
						<b-col sm="2">
							<b-form-group label="Jam" label-for="v-Jam">
								<v-select id="v-Jam" v-model="t_jam" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_jam_opt" class="select-size-sm" :disabled="t_jam_opt.length == 1" placeholder="Pilih Jam" />
							</b-form-group>
						</b-col>
						<b-col sm="2">
							<b-form-group label="Menit" label-for="v-Menit">
								<v-select id="v-Menit" v-model="t_menit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_menit_opt" class="select-size-sm" :disabled="t_menit_opt.length == 1" placeholder="Pilih Menit" />
							</b-form-group>
						</b-col> -->
					</b-row>
				</b-col>
				<!-- <b-col cols="1">
					<b-button variant="primary" class="mt-2 mr-1 float-right"  @click="fetch()" :disabled="!(t_type_message != null && t_type_message != '' && t_station != null && t_station != '' && t_tanggal != null && t_tanggal != '' && t_jam != null && t_jam != '' && t_menit != null && t_menit != '')"> Fetch </b-button>
				</b-col> -->
			</b-row>
			<b-row>
				<b-col>
					<b-form-group label="Sandi" label-for="textarea-sandi">
						<b-form-textarea rows="6" max-rows="9" id="textarea-sandi" placeholder="Sandi" v-model="sandi" class="font-weight-bolder"></b-form-textarea>
					</b-form-group>
				</b-col>
            </b-row>
			<b-row class="mt-1">
				<b-col v-if="isNewData">
					<!-- <b-button variant="primary" class="mr-1 float-right"  @click="submitForm()" :disabled="!(input.station_name && sandi && input.type_message != '0')"> Send </b-button> -->
					<b-button variant="primary" class="mr-1 float-right"  @click="submitForm()" :disabled="!sandi"> 
						<span v-if="hasChecked">Send</span>	
						<span v-else>Check</span>	
					</b-button>
				</b-col>
				<!-- <b-col v-if="isNewData">
					<b-button variant="primary" class="mr-1 float-right"  @click="submitForm()" :disabled="!(t_type_message != null && t_type_message != '' && t_station != null && t_station != '' && t_tanggal != null && t_tanggal != '' && t_jam != null && t_jam != '' && t_menit != null && t_menit != '')"> Send </b-button>
				</b-col> -->
				<!-- <b-col v-if="!isNewData">
					<b-button variant="primary" class="mr-1 float-right"  @click="submitForm()" :disabled="!(t_type_message != null && t_type_message != '' && t_station != null && t_station != '' && t_tanggal != null && t_tanggal != '' && t_jam != null && t_jam != '' && t_menit != null && t_menit != '')"> Update & Send </b-button>
				</b-col> -->
			</b-row>
		</b-card>
		<b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
	</div>
</template>

<script>
import { BTabs, BTab, BCard, BRow, BCol, BFormTextarea, BButton, BFormGroup, BOverlay, BFormDatepicker, BFormInput } from "bootstrap-vue";
import vSelect from "vue-select";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
	components: {
		BTabs,
		BTab,
		BCard,
		BRow,
		BCol,
		BFormTextarea,
		BButton,
		BFormGroup,
		vSelect,
		BOverlay,
		BFormDatepicker,
		BFormInput
	},

	data() {
		return {
			roles: null,
			input: {
				station_id: null,
				station_wmo_id: null,
				station_name: '',
				path: '',
				ttaaii: '',
				cccc: '',
				dd:'',
				hh:'',
				mm:'',
				type_message: '0',				
			},
			data_station: null,
			t_station: "",
			t_station_selected: "",
			t_station_opt: [],
			t_type_message: "",
			t_type_message_opt: [],
			t_jam: "",
			t_jam_opt: [],
			t_menit: "",
			t_menit_opt: [],
			t_tanggal: "",
			showLoading: false,
			sandi: "",
			sandi_pibal:[],
			isNewData: true,
			hasChecked: false,
		}
	},

	mounted() {
		// console.log('mounted')
		this.listStasiun()
		this.listRoles()
		this.listTypeMessage()
	},

	methods: {
		listRoles() {
				let userLogin =  JSON.parse(localStorage.getItem('userLogin'))
				let role = userLogin.role
				let roles = {
					isRoleAdd: false,
					isRoleEdit: false,
					isRoleView: false,
					isRoleDelete: false
				}
				if(role != "admin") {
					let myRoles = helper.getMenuRoles(userLogin.listperm)

					if (myRoles.hasOwnProperty("MegaEntry")) {
						if (myRoles.MegaEntry.includes("Add")) {
							roles['isRoleAdd'] = true
						}
						if (myRoles.MegaEntry.includes("Modify")) {
							roles['isRoleEdit'] = true
						}
						if (myRoles.MegaEntry.includes("View")) {
							roles['isRoleView'] = true
						}
						if (myRoles.MegaEntry.includes("Delete")) {
							roles['isRoleDelete'] = true
						}
					}
				}
				else {
					roles['isRoleAdd'] = true
					roles['isRoleEdit'] = true
					roles['isRoleView'] = true
					roles['isRoleDelete'] = true
				}

				this.roles = roles
		},

		listTypeMessage() {
			let data_station = [
				{value: "1", text: "SINOPTIK"},
				{value: "2", text: "PILOT"},
				{value: "3", text: "TEMP"},
				{value: "4", text: "METAR"},
				{value: "5", text: "SPECI"},
				{value: "6", text: "TAF"},
				{value: "7", text: "BERITA CLIMAT"},
				{value: "8", text: "WXREV"}
			]	

			this.t_type_message_opt = data_station
		},
		
		pad(val, n) {
			return `${"0".repeat(n)}${val}`.substr(-n, n);
			// return (d < 10) ? '0' + d.toString() : d.toString();
		},

		listJam() {
			let jam_opt = []
			if (this.t_type_message.value == "1") {
				for (var i = 0; i < 24; i++) {
					jam_opt.push(this.padZero(i,2))
				}	
				this.t_jam_opt = jam_opt		
				this.t_menit_opt = [{value: "00", text: "00"}]	
				this.t_menit = "00"
				// console.log('')
			}
			else if (this.t_type_message == "Metar"){

			}
			else {
				this.t_jam_opt = []
				this.t_jam_opt = ""
				this.t_menit_opt = []
				this.t_menit = ""
			}

		},

		async listStasiun() {
			try {
				this.showLoading = true
				const { data } = await metadata.getAllStasiunWithWMO();
				this.t_station_opt = [];
				let first = {};
				for (var i = 0; i < data.length; i++) {
					const data_station = {
						value: data[i].station_wmo_id,
						text: data[i].station_wmo_id + " - " + data[i].station_name,
						path: data[i].path,
					};
					first = data_station;

					this.t_station_opt.push(data_station);
					this.t_station_selected = "";
				}

				if (this.t_station_opt.length == 1) {
					this.t_station_selected = first;
					this.t_station_path = first.path;
				}
				// console.log('listStasiun', this.t_station_opt, data)
			} catch (e) {
				this.showLoading = false
				// console.log(e);
			}	
			this.showLoading = false
		},

		fetch() {
			this.sandi = ""
			this.loadDataExist()
		},

		async loadDataExist() {
			// console.log('loadDataExist')
			if (!this.t_station_selected) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Stasiun belum diisi",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return
			}
			else if (!this.t_tanggal) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Tanggal belum diisi",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return
			}
			else if (!this.t_jam) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Jam belum diisi",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return
			}
			else if (!this.t_menit) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Menit belum diisi",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return
			}
			let id = this.t_type_message.value + this.t_station_selected.value + this.t_tanggal.toString().replace(/-/g,'') + this.t_jam + this.t_menit
			let url_gts = helper.getBaseUrl() + this.t_station_selected.path + "/gts/"+id;
			this.showLoading = true
			await axios
				.get(url_gts, helper.getConfig())
				.then((response) => {
					const { data } = response;
					// console.log(data, response)
					this.popupData(data);
					this.isNewData = false
					this.showLoading = false
				})
				.catch((error) => {
					this.showLoading = false
					// console.log("cekExistData - eeror");
					this.isNewData = true
				});
		},

		popupData(data) {
			// console.log('popupData', data)
			if (data) {
				this.sandi = data.sandi_gts
			}
		},

		submitForm() {
			if (!this.hasChecked && this.sandi) {
				this.t_station_selected = ""
				this.onCheckSandi(this.sandi)
			}
			else if (this.hasChecked && this.sandi) {
				if (!(this.sandi && this.sandi.trim() != '')) {
					this.hasChecked = false
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Sandi masih belum diisi",
							icon: "BellIcon",
							variant: "danger",
						},
					});
					return
				}
				else if (!(this.t_station_selected)) {
					this.hasChecked = false
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Stasiun masih belum dipilih",
							icon: "BellIcon",
							variant: "danger",
						},
					});
					return
				}
				else if (this.input.type_message == '0') {
					this.hasChecked = false
					this.$swal({
						title: "Header Tidak Ditemukan / Tidak Cocok",
						text: "Tidak ditemukan header yang cocok atau header belum ada pada metadata.",
						icon: "error",
						customClass: {
						confirmButton: "btn btn-danger",
						},
						buttonsStyling: false,
					});
					return;
				}

				let text = "Data akan disimpan dalam database dan dikirim ke BmkgSwitching"
				let title = "Apakah Anda yakin untuk simpan data ?"
				this.$swal({
					title: title,
					text: text,
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Send",
					cancelButtonText: "Cancel",
					allowOutsideClick: false,
					allowEscapeKey: false,
					customClass: {
						confirmButton: "btn btn-primary",
						cancelButton: "btn btn-outline-danger ml-1",
					},
					buttonsStyling: false,
				}).then((result) => {
					if (result.value) {
						// if (this.isNewData) {
							this.submitFormComfirm();
						// }
						// else {
						// 	this.updateFormComfirm();
						// }
					} else if (result.dismiss === "cancel") {
						return;
					}
				});
			}

		},

		async submitFormComfirm() {
			let date = new Date()
			let intTM = parseInt(this.input.type_message)
			// let id = ""+this.padZero(intTM, 2) + this.input.station_wmo_id + date.getFullYear()+this.padZero((date.getMonth()+1),2)+this.padZero(date.getDate(),2)+this.padZero(date.getHours(),2)+this.padZero(date.getMinutes(),2)
			let id = ""+
					this.padZero(intTM, 2) + 
					this.t_station_selected.value + 
					date.getFullYear() + 
					this.padZero((date.getMonth()+1),2) +
					this.padZero(date.getDate(),2) + 
					this.padZero(date.getHours(),2) + 
					this.padZero(date.getMinutes(),2)+
					this.padZero(date.getSeconds(),2)
			
			// console.log('this.input.type_message', this.input.type_message)
			if (this.input.type_message == 2 || this.input.type_message == 3) {
				this.splitSandi()
			}

			const post_data = {
				"@type": "GTSMessage",
				"id": id,
				"type_message": this.input.type_message,
				"sandi_gts": this.sandi,
				"timestamp_sent_data": date.getFullYear()+ "-" + this.padZero(date.getMonth()+1,2) + "-" + this.padZero(date.getDate(),2) + "T" + this.padZero(date.getHours(),2) + ":" + this.padZero(date.getMinutes(),2) + ":" + this.padZero(date.getSeconds(),2),
				"timestamp_data": date.getFullYear()+ "-" + this.padZero(date.getMonth()+1,2) + "-" + this.padZero(this.input.dd,2) + "T" + this.input.hh + ":" + this.input.mm + ":00",
				"wmoid": this.t_station_selected.value,//this.input.station_wmo_id,
				"ttaaii": this.input.ttaaii,
				"cccc": this.input.cccc
			}

			if (this.input.type_message == 2 || this.input.type_message == 3) {
				if (this.sandi_pibal.length > 0) {
					post_data['sandi_pibal'] = this.sandi_pibal
				}
			}

			let url_gts = helper.getBaseUrl() + this.input.path + "/gts";
			// console.log('post_data', post_data, url_gts)
			this.showLoading = true
			await axios
				.post(url_gts, post_data, helper.getConfig())
				.then((response) => {
					if (response.status == 201 || response.status == 200 || response.status == 204) {
						this.$swal({
							title: "Input Data GTS Messages Berhasil",
							text: "Input Data GTS Message",
							icon: "success",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						this.hasChecked = false
						this.showLoading = false;
						this.clearData();
					}
				})
				.catch((error) => {
					this.showLoading = false;
					// console.log(error, error.response, "Input Data Error");
					
					if (error.response.status == 401) {
						this.$swal({
							title: "Unauthorized",
							icon: "error",
							text: "Anda tidak memiliki otorisasi untuk entry data ini. Periksa sesi anda atau hubungi admin untuk memberi otorisasi.",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
					}
					else if (error.response.status == 412) {
						// let data = error.response.data
						// console.log('data', data)
						this.$swal({
							title: "Kesalahan Format Data",
							icon: "error",
							text: 'Terdapat kesalahan pada data karena tidak sesuai format yang ditetapkan',
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						// if (data.hasOwnProperty('message')) {
						// 	let errorMessage = data.error.message
						// 	this.$swal({
						// 		title: "Error (412)!",
						// 		text: errorMessage,
						// 		customClass: {
						// 		confirmButton: "btn btn-primary",
						// 		},
						// 		buttonsStyling: false,
						// 	});
						// }
					}
					else {
						this.$swal({
							title: "Something wrong!",
							text: ""+error.response.status,
							icon: "error",
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
					}
				});

			this.clearData()
			this.showLoading = false
		},

		splitSandi () {
			
			let arrSandi = this.sandi.split(/[\r=]+/)
			// console.log('splitSandi', arrSandi)
			for (let idx in arrSandi) {
				if (arrSandi[idx] != "" && arrSandi[idx].indexOf(' ') >= 0) {
					
					let elSandi = arrSandi[idx]+"="
					let isLoop = true
					while(isLoop) {
						if (elSandi && elSandi.charAt(0) == '\n') { 
							elSandi = elSandi.substr(1); 
						}
						else {
							isLoop = false
						}					
					}
					this.sandi_pibal.push(elSandi)					
				}
			}
			// console.log('sandi_pibal',  this.sandi_pibal)
		},

		// async updateFormComfirm() {
		// 	// console.log('submitFormComfirm', this.t_tanggal, this.t_station, this.t_type_message, this.t_jam, this.t_menit)
		// 	let id = this.t_type_message.value + this.t_station.value + this.t_tanggal.toString().replace(/-/g,'') + this.t_jam + this.t_menit
		// 	let date = new Date()
		// 	let tmp_sandi = this.sandi.split(" ")
		// 	const post_data = {
		// 		"@type": "GTSMessage",
		// 		// "id": id,
		// 		// "type_message": this.t_type_message.value,
		// 		"sandi_gts": this.sandi,
		// 		// "timestamp_sent_data": date.getFullYear()+ "-" + this.pad(date.getMonth()) + "-" + this.pad(date.getDate()) + "T" + this.pad(date.getHours()) + ":" + this.pad(date.getMinutes()) + ":" + this.pad(date.getSeconds()),
		// 		// "timestamp_data": this.t_tanggal + "T" + this.t_jam + ":" + this.t_menit,
		// 		// "wmoid": this.t_station.value,
		// 		"ttaaii": tmp_sandi.length > 1 ? tmp_sandi[0] : "",
		// 		"cccc": tmp_sandi.length > 2 ? tmp_sandi[1] : ""
		// 	}
			

		// 	let url_gts = helper.getBaseUrl() + this.t_station.path + "/gts/" +id;
		// 	// console.log('post_data', post_data, url_gts)
		// 	this.showLoading = true
		// 	await axios
		// 		.patch(url_gts, post_data, helper.getConfig())
		// 		.then((response) => {
		// 			if (response.status == 201 || response.status == 200 || response.status == 204) {
		// 				this.$swal({
		// 					title: "Update Data GTS Messages Berhasil",
		// 					text: "Update Data GTS Message",
		// 					icon: "success",
		// 					customClass: {
		// 					confirmButton: "btn btn-primary",
		// 					},
		// 					buttonsStyling: false,
		// 				});
		// 				this.showLoading = false;
		// 				this.clearData();
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			this.showLoading = false;
		// 			error_handler(this, error, "Sinoptik", "Input Data Error");
		// 		});

		// 	// this.clearData()
		// 	this.showLoading = false
		// },

		

		async onCheckSandi(value) {
			// console.log('onCheckSandi', value)
			if (value) {
				// String(value).replace('\n', " ")
				// console.log('vsandi after replace \n', value)
				// let vsandi = value.split(" ")
				let vsandi = value.split(/[,\s]+/)
				// console.log('vsandi after replace \n', vsandi)
				if (vsandi.length > 3) {
					for(let i = 0; i < vsandi.length; i++) {
						if (vsandi[i] == '') {
							vsandi.splice(i, 1)
							if (i >= 0) {
								i = (i-1)
							}
						}
						else {
							if (vsandi[i].indexOf('\n') > -1) {
								vsandi[i] = String(vsandi[i]).replace('\n', ' ')
							}
						}
					}
					this.input = {
						station_id: null,
						station_wmo_id: null,
						station_name: '',
						path: '',
						ttaaii: '',
						cccc: '',
						dd:'',
						hh:'',
						mm:'',
						type_message: '0'
					}
					// console.log('vsandi - after', vsandi)
					this.input.ttaaii = vsandi[0]
					this.input.cccc = vsandi[1]
					let date = vsandi[2]
					this.input.dd = date.charAt(0)+date.charAt(1)
					this.input.hh = date.charAt(2)+date.charAt(3)
					this.input.mm = date.charAt(4)+date.charAt(5)
					// console.log('vsandi - after', vsandi, this.input)
					let isFind = false
					for (let idx in vsandi) {
						if (vsandi[idx].trim().charAt(0) == '9' && vsandi[idx].trim().length == 5 ) {
							this.findStationbyWmoId(vsandi[idx].trim())
							isFind = true
							break;
						}
					}

					if (!isFind) {
						this.getTypeMessage()
					}
					
					this.hasChecked = true
					//tambahkan notif tidak ada wmoid
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Sandi tidak valid",
							icon: "BellIcon",
							variant: "danger",
						},
					});
				}
			}
		},

		async findStationbyWmoId(wmoid) {
			this.showLoading = true
			try {
				
				const { data } = await metadata.getAllStasiunByWmo(wmoid);
				// console.log('findStationbyWmoId', wmoid, data)				
				if (data) {
					var found = this.t_station_opt.filter(function(item) { return item.value === data[0].station_wmo_id });
					// console.log('found station', found)
					if (found && found.length > 0) {

						this.input.station_name = data[0].station_name
						this.input.station_wmo_id = data[0].station_wmo_id
						this.input.station_id = data[0].station_id
						this.input.path = data[0].path
						this.t_station_selected = {"value": data[0].station_wmo_id, "text": data[0].station_wmo_id+" - "+data[0].station_name, "path": data[0].path}
													
						// let url = helper.getBaseUrl() + data[0].path;
						// axios.get(url, helper.getConfig()).then((response) => {
						// 	if (response.status == 201 || response.status == 200 || response.status == 204) {
						// 		let profile = response.data;
						// 		// console.log('profile', profile)
						// 		this.data_station = profile
						// 		this.getTypeMessage()
						// 	}
						// });
						this.hasChecked = true
						this.showLoading = false
					}
					else {
						this.t_station_selected = ""
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Data stasiun pada sandi tidak ditemukan sama dengan yang ada pada list stasiun.",
								icon: "BellIcon",
								variant: "danger",
							},
						});
					}
					this.getTypeMessage()
					this.showLoading = false
					
				}
			}
			catch (error) { 
				this.showLoading = false
			}
		},

		getTypeMessage() {

			let ttaaii = this.input.ttaaii
			let cccc = this.input.cccc

			if (ttaaii.includes('SMID') || ttaaii.includes('SIID') || ttaaii.includes('SNID') ) { //SINOP
				this.input.type_message = '1'
			}
			else if (ttaaii.includes('SAID') ) { //METAR
				this.input.type_message = '4'
			}
			else if (ttaaii.includes('SPID') ) { //SPECI
				this.input.type_message = '5'
			}
			else if (ttaaii.includes('MMID') ) { //WXREV
				this.input.type_message = '8'
			}
			else if (ttaaii.includes('USID') || ttaaii.includes('UKID') || ttaaii.includes('ULID') || ttaaii.includes('UEID') ) { //TEMP
				this.input.type_message = '3'
			}
			else if (ttaaii.includes('UPID') || ttaaii.includes('UGID') || ttaaii.includes('UIID') || ttaaii.includes('UHID') || ttaaii.includes('UQID') ) { //PILOT
				this.input.type_message = '2'
			}
			else if (ttaaii.includes('CSID') ) { //CLIMAT
				this.input.type_message = '7'
			}
			else if (ttaaii.includes('SEID') ) { //SEISMIC 
				this.input.type_message = '10'
			}
			else if (ttaaii.includes('FTID') ) { //TAF 
				this.input.type_message = '6'
			}
			else if (ttaaii.includes('CUID') ) { //CLIMAT TEMP 
				this.input.type_message = '9'
			}
			else if (ttaaii.includes('WEID') ) { //WARNING TSUNAMI
				this.input.type_message = '11'
			}
			else if (ttaaii.includes('WSID')) { //WARNING SIGMET
				this.input.type_message = '12'
			}
			else if (ttaaii.includes('WTID')) { //WARNING CYLCONE
				this.input.type_message = '13'
			}
			else if (ttaaii.includes('CXXX') ) { //CROSSWIND 
				this.input.type_message = '14'
			}
			// console.log('getTypeMessage', ttaaii, cccc, this.data_station)
			// let igts = this.data_station['BmkgSatu.metadata.gts.IGTS']
			
			// if (igts.sinop_gts_main_header && igts.sinop_gts_main_header.includes(ttaaii) && igts.sinop_gts_main_header.includes(cccc)){
			// 	this.input.type_message = '1'
			// }
			// else if (igts.sinop_gts_intermediate_header && igts.sinop_gts_intermediate_header.includes(ttaaii) && igts.sinop_gts_intermediate_header.includes(cccc)) {
			// 	this.input.type_message = '1'
			// }
			// else if (igts.sinop_gts_non_standard_hour_header && igts.sinop_gts_non_standard_hour_header.includes(ttaaii) && igts.sinop_gts_non_standard_hour_header.includes(cccc)) {
			// 	this.input.type_message = '1'
			// }
			// else if (igts.metarspeci_gts_metar_header && igts.metarspeci_gts_metar_header.includes(ttaaii) && igts.metarspeci_gts_metar_header.includes(cccc)) {
			// 	this.input.type_message = '4'
			// }
			// else if (igts.metarspeci_gts_speci_header && igts.metarspeci_gts_speci_header.includes(ttaaii) && igts.metarspeci_gts_speci_header.includes(cccc)) {
			// 	this.input.type_message = '5'
			// }
			// else if (igts.wxrev_gts_header && igts.wxrev_gts_header.includes(ttaaii) && igts.wxrev_gts_header.includes(cccc)) {
			// 	this.input.type_message = '8'
			// }
			// else if (igts.pilot_gts_ppaa_header && igts.pilot_gts_ppaa_header.includes(ttaaii) && igts.pilot_gts_ppaa_header.includes(cccc)) {
			// 	this.input.type_message = '2'
			// }
			// else if (igts.pilot_gts_ppbb_header && igts.pilot_gts_ppbb_header.includes(ttaaii) && igts.pilot_gts_ppbb_header.includes(cccc)) {
			// 	this.input.type_message = '2'
			// }
			// else if (igts.pilot_gts_ppcc_header && igts.pilot_gts_ppcc_header.includes(ttaaii) && igts.pilot_gts_ppcc_header.includes(cccc)) {
			// 	this.input.type_message = '2'
			// }
			// else if (igts.pilot_gts_ppdd_header && igts.pilot_gts_ppdd_header.includes(ttaaii) && igts.pilot_gts_ppdd_header.includes(cccc)) {
			// 	this.input.type_message = '2'
			// }
			// else if (igts.pilot_gts_ppaa_ppbb_national_bilateral_header && igts.pilot_gts_ppaa_ppbb_national_bilateral_header.includes(ttaaii) && igts.pilot_gts_ppaa_ppbb_national_bilateral_header.includes(cccc)) {
			// 	this.input.type_message = '2'
			// }
			// else if (igts.temp_gts_ttaa_header && igts.temp_gts_ttaa_header.includes(ttaaii) && igts.temp_gts_ttaa_header.includes(cccc)) {
			// 	this.input.type_message = '3'
			// }
			// else if (igts.temp_gts_ttbb_header && igts.temp_gts_ttbb_header.includes(ttaaii) && igts.temp_gts_ttbb_header.includes(cccc)) {
			// 	this.input.type_message = '3'
			// }
			// else if (igts.temp_gts_ttcc_header && igts.temp_gts_ttcc_header.includes(ttaaii) && igts.temp_gts_ttcc_header.includes(cccc)) {
			// 	this.input.type_message = '3'
			// }
			// else if (igts.temp_gts_ttdd_header && igts.temp_gts_ttdd_header.includes(ttaaii) && igts.temp_gts_ttdd_header.includes(cccc)) {
			// 	this.input.type_message = '3'
			// }
			// else if (igts.klimat_gts_berita_klimat_header && igts.klimat_gts_berita_klimat_header.includes(ttaaii) && igts.klimat_gts_berita_klimat_header.includes(cccc)) {
			// 	this.input.type_message = '7'
			// }
			// else if (igts.klimat_gts_klimat_temp_header && igts.klimat_gts_klimat_temp_header.includes(ttaaii) && igts.klimat_gts_klimat_temp_header.includes(cccc)) {
			// 	this.input.type_message = '7'
			// }

			if (this.input.type_message == '0') {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Header Tidak Ditemukan.",
						icon: "BellIcon",
						variant: "danger",
					},
				}); 
			}
		},

		padZero(val, n) {
			return `${"0".repeat(n)}${val}`.substr(-n, n);
		},

		clearData() {
			// this.t_station = ""
			// this.t_station_opt = []
			this.showLoading = false
			this.sandi = ""
			this.sandi_pibal = []
			this.t_tanggal = ""
			this.t_jam = ""
			this.input.station_name = ""
			this.input.type_message = "0"
			// this.t_menit = ""
			this.showLoading = false
		}
	},

	watch: {

		t_station_selected : function(value) {
			if (value) {
				let station = String(value.text).split('-')
				this.input.station_wmo_id = value.value
				this.input.station_name = station[1].trim()
				this.input.path = value.path
				let m_path = String(value.path).split('/')
				this.input.station_id = m_path[m_path.length-1]
			}
			// console.log('t_station_selected', value, this.input)
		},

		t_type_message(value) {
			// console.log('watch - t_type_message', value)
			if (value) {
				if (value.value == "1") {
					this.listJam()
				}
				this.sandi = ""
			}
		},

		sandi(value) {
			// console.log('wath-sandi', value)
			// if (!value) {
				this.hasChecked = false
			// }
			// this.onCheckSandi(value)
		}

		// t_jam(value) {
		// 	if (value) {
		// 		if (this.menit) {
		// 			this.loadDataExist()
		// 		}
		// 	}
		// }
	}	
};
</script>
